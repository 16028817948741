a{
  color: black;
  text-decoration: none;
  
}

.signup-card{
  border-radius: 15px !important;
 
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2) !important;
}




.text-container p {
  position: relative;
  font-weight: bold;
}

.text-container p::before,
.text-container p::after {
  content: "";
  position: absolute;
  margin-left: 10px;
  margin-right: 10px;
  width: 220px; /* Adjust this value as needed */
  height: 1px;
  background-color: rgb(182, 181, 181);
}

.text-container p::before {
  top: 50%;
  right: 100%;
}

.text-container p::after {
  top: 50%;
  left: 100%;
}




.gradient-text {
  background: linear-gradient(90deg, #3C4DA7 0%, #171E41 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Use the text as a mask for the gradient */
}

.container {
  position: relative;
}

.container img {
  display: block;
  width: 100%;
  height: auto;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  width: 20%; /* Adjust the width of the blend effect */
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)); /* Adjust the colors and opacity as needed */
  mix-blend-mode: overlay; /* Adjust blend mode as needed */
  /* mask-image: linear-gradient(to left, transparent 0%, black 10%, black 90%, transparent 100%); */
}
.sectionsvg{
  width: 100vw;
  height: 100vh;
 }

html {
  max-width: 1450px;
  margin-left: auto;
    margin-right: auto;
  overflow-x: hidden;
  }
  .footer-svg{
  width: 100vw;
position: relative;
}
   
@media  screen and (max-width:900px) {
.icon-features{
  margin-top: 40%;

  width: 100%;}
}
@media screen and (max-width: 600px) {
  .section-btn{
    margin-left: 30% !important;
  }
 
  .icon-features{
    margin-top: 40%;
    width: 60%;
    font-size: 25pt !important;
    
  }
  .icon-test{
    font-size: 1.6rem !important;
  }
 .text-hd{
  text-align: center !important;
  padding:0px 7px;
 }
 
  .logo-bottom{
    width: 100px;
    margin:0px 10px;
  }
  h4{
    font-size: 1rem !important;
  }
  h3{
    font-size: 1.2rem !important;

  }
  h5{
    font-size: 0.9rem !important;
  }
  h6{
    font-size: 0.8rem !important;
  }
  a{
    font-size: 0.8rem !important;
  }
  p{
font-size: 0.7rem !important;
  }
.icon{
  font-size: 3rem !important;
}
  button{
    font-size: 0.8rem !important;
    width: fit-content !important;
  }
  .logo-icon{
    font-size: 1rem !important;
  }
}

.text-gray-900{
  font-weight: normal;
}

.arrow{
  width: 100px;
  height: 25px;
}


.color{
  color: #818181;
}
