body {
  overscroll-behavior-y: contain !important;
  max-width: 1450px;
  overflow-x: hidden;
}
.price {
  width: 310px !important;
  height: 370px !important;
}

.export {
  color: white;
  font-weight: bold;
  text-decoration: none;
}
.carousel-root .carousel .slide {
  height: 100vh !important;
  @media screen and (max-width: 688px) {
    height: 100vh !important;
  }
}
.take {
  text-decoration: none;
}
.slide .legend {
  position: relative !important;
  z-index: 50 !important;
  font-size: 50pt !important;
  margin-top: -40% !important;
  background: transparent !important;
  font-weight: bolder;
  color: #1976d2 !important;
  opacity: 1 !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(245, 243, 243);
}
.slide .img_div {
  opacity: 0.4;
}
.black {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
}
.slide .legend:hover {
  position: relative !important;
  z-index: 50 !important;
  font-size: 50pt !important;
  margin-top: -40% !important;
  background: transparent !important;
  font-weight: bolder;
  color: #1976d2 !important;
  opacity: 1 !important;
}

@media screen and (max-width: 768px) {
  .slide .legend {
    position: relative !important;
    z-index: 50 !important;
    font-size: 30pt !important;
    margin-top: -30% !important;
    background: transparent !important;
    font-weight: bolder;
    color: #1976d2 !important;
    opacity: 1 !important;
  }
}
@media screen and (max-width: 500px) {
  .slide .legend {
    position: relative !important;
    z-index: 50 !important;
    font-size: 20pt !important;
    margin-top: -20% !important;
    background: transparent !important;
    font-weight: bolder;
    color: #1976d2 !important;

    opacity: 1 !important;
  }
}

@media screen and (max-width: 375px) {
  .slide .legend {
    position: relative !important;
    z-index: 50 !important;
    font-size: 20pt !important;
    margin-top: 10% !important;
    background: transparent !important;
    font-weight: bolder;
    color: #1976d2 !important;
    opacity: 1 !important;
  }
}

.image {
  position: absolute;
  z-index: 55;
  margin: 30px 100px;
  top: 30;
  left: 200;
}
.ck.ck-editor__editable:not(
    .ck-editor__nested-editable
  ).ck-rounded-corners.ck-focused {
  border: 1px solid transparent;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(192, 190, 190);
  min-height: 130px;
  max-height: 300px;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
  border: 1px solid transparent;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(223, 220, 220);
  min-height: 130px;
  max-height: 300px;
}

* {
  margin: 0;
  padding: 0;

  body::-webkit-scrollbar {
    width: 10px;
  }
  #error {
    margin: 2vh auto;
    max-width: 800px;
    display: none;
    text-align: center;
    border-radius: 5px;
    padding: 10px;
    position: sticky;
    top: 2vh;
  }
  #check {
    display: none;
  }

  body::-webkit-scrollbar-track {
    box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.3);
  }

  body::-webkit-scrollbar-thumb {
    background-color: #1976d2;
    // outline: 1px solid #1976d2;
  }

  .color {
    background-color: #e1f4ef;
    // outline: 1px solid #1976d2;
  }
}

.button-submit {
  padding: 10px !important;
  border-radius: 5px;
}
// UPLOAD BUTTON
.upload-pic {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
  }

  .btn {
    border: none;
    color: #ffffff;
    background-color: #1976d2;
    padding: 8px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
  }

  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}
.class-view::-webkit-scrollbar {
  width: 10px;
}
.class-view::-webkit-scrollbar-track {
  box-shadow: inset 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.class-view::-webkit-scrollbar-thumb {
  background-color: #1976d2;
  // outline: 1px solid #1976d2;
}

@media screen and (max-width: 768px) {
  .loader {
    margin-left: 50px;
    height: 100px !important;
    width: 100px !important;
  }
}
@media screen and (min-width: 1140px) {
  .header_word {
    display: inline-block;
    width: 60vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-width: 640px) {
  .header_word {
    display: inline-block;
    width: 50vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (min-width: 200px) {
  .header_word {
    display: inline-block;
    width: 35vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// .login-box {
// 	width: 100%;
// 	display: grid;

// 	 position: absolute;
// 	 top: 10vh;
// 	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
// 	row-gap: 10px;
// 	 z-index: 99;

// 	@media screen and (max-width: 1620px) {
// 		 grid-template-columns: 1fr 1fr 1fr 1fr;
// 	}
// 	@media screen and (max-width: 1190px) {
// 		 grid-template-columns: 1fr 1fr 1fr;
// 	}
// 	@media screen and (max-width: 910px) {
// 		 margin-top: -10vh;
// 	}
// 	@media screen and (max-width: 750px) {
// 		 margin-top: -15vh;
// 		 grid-template-columns: 1fr 1fr;
// 	}
// 	@media screen and (max-width: 688px) {
// 		 margin-top: -25vh;
// 	}
// 	@media screen and (max-width: 500px) {
// 		 margin-top: -25vh;
// 		 grid-template-columns: 1fr;
// 	}
// 	@media screen and (max-width: 375px) {
// 		// margin-top: -20vh;
// 	}
// }

// LIST
.list {
  margin: 8px 0;
}
.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
  width: 96%;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: auto;
  margin-bottom: 5px;
  padding: 17px;
  border-radius: 4px;
  outline: none;
  font-size: 17px;
  border: 1px solid rgb(192, 190, 190);
  width: 96%;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  background: #1680cc;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 7px;
  margin: 5px 5px;
  border-radius: 5px;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  margin-left: 10px;
  background: none;
  color: white;
}

.color {
  color: #dcdfe1;
}

.input-tag {
  font: inherit;
  letter-spacing: inherit;
  box-sizing: content-box;
  background: none;
  margin: 0;
  display: block;
  min-width: 0;
  width: 90%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  border: 1px solid black;
  border-radius: 5px;
  outline: none;
}
.back {
  font-size: 30px !important;
  width: 40px;
}

.hg-rows {
  margin-bottom: 10px;
}
.hg-row {
  height: 50px;
}
.hg-button {
  font-size: 25px !important;
  height: 50px !important;
}
.close {
  font-size: 20px !important;
  color: red;
  background-color: #0b67a8;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
.txt {
  color: linear-gradient(to right, #0005a3, #000369) !important;
}
/* Imports */
@import "./admin/profile.scss";
.ck p {
  font-size: 16pt !important;
}
